import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/markoletic/Documents/Code/armada-js-website/src/components/markdown-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TERMS OF USE AND SALE`}</h1>
    <p>{`Armada Konferencije doo`}</p>
    <p>{`Last updated: May 15th, 2023`}</p>
    <p>{`These Terms of Use and Sale apply to the provision of services organized by Armada Konferencije DOO with headquarters at Dositejeva Street 7/26 in Novi Sad via the website `}<a parentName="p" {...{
        "href": "https://armada-js.com."
      }}>{`https://armada-js.com.`}</a>{`
These Terms of Use and Sale define the general conditions, individual rights and obligations and the method of purchase.`}</p>
    <p>{`The use of the website ‘`}<a parentName="p" {...{
        "href": "https://armada-js.com%E2%80%99"
      }}>{`https://armada-js.com’`}</a>{` is governed by these Terms of Use and Sale, which the visitor/user must read carefully, because the use of the website ‘`}<a parentName="p" {...{
        "href": "https://armada-js.com%E2%80%99"
      }}>{`https://armada-js.com’`}</a>{` is considered as unconditional acceptance of these Terms of Use and Sale.
If the visitor/user does not agree with the terms of use of this document, he must not use the services and content of the website ‘`}<a parentName="p" {...{
        "href": "https://armada-js.com%E2%80%99"
      }}>{`https://armada-js.com’`}</a>{`.
These Terms of Use and Sale are a legally binding contract between you and Armada Konferencije DOO.
By registering/using and purchasing, you confirm that you have read, understood and agree to enter into this Agreement.
If you do not agree with the Agreement, either in part or in full, do not access, register and place orders or use Armada Konferencije DOO services in any other way.`}</p>
    <p>{`This Agreement is considered concluded at the moment of confirmation of the order by Armada Konferencije DOO.`}</p>
    <h3>{`BASIC PROVISIONS`}</h3>
    <p>{`Armada Konferencije DOO, with headquarters at Dositejeva Street 7/26 in Novi Sad, PIB: 113186670, MB: 21819379, contact e-mail: `}<a parentName="p" {...{
        "href": "mailto:contact@armada-js.com"
      }}>{`contact@armada-js.com`}</a>{`, is a company that, as part of its registered activity, organizes seminars, conferences and other events.`}</p>
    <p>{`Armada Konferencije DOO sells tickets for the mentioned events via the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`, which in terms of the law constitutes distance trading.`}</p>
    <h3>{`METHOD OF PURCHASE`}</h3>
    <p>{`Tickets can be purchased via the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` by registered and unregistered users of the said website, as follows:`}</p>
    <p>{`Step 1: click on the “Tickets” option, which automatically redirects the customer to the site/portal of the sales partner, through which the ticket is purchased, whereby the user accepts General conditions and Privacy Policy of the portal/site of the sales partner;`}</p>
    <p>{`Step 2: on the site/portal of the sales partner, enter the required data: first name`}<em parentName="p">{`, last name`}</em>{`, e-mail address`}<em parentName="p">{`, company`}</em>{`, position in the company`}<em parentName="p">{`, prefix/title, CC/alternative e-mail address, contact mobile phone, with a note that data marked with `}</em>{` are mandatory and will be forwarded to Armada Konferencije DOO as such.`}</p>
    <p>{`Step 3: paying for the ticket.`}</p>
    <p>{`After receiving the order, Armada Konferencije DOO or its sales partner will confirm the order by sending an order confirmation notification to the e-mail address of the user/ticket buyer.
After paying for the ticket, the ticket is sent to the user/customer from the e-mail address `}<a parentName="p" {...{
        "href": "mailto:tickets@armada-js.com"
      }}>{`tickets@armada-js.com`}</a></p>
    <p>{`The user/buyer of the ticket has the possibility to modify certain data submitted during the purchase of the ticket (data submitted in step number 2. from the first paragraph) no later than 15 (fifteen) days before the day of the event.
The card user/buyer can modify the data related to his e-mail address, company, position in the company, prefix/title, CC/alternative e-mail address, and contact mobile phone, while data about his first and last name in which case it cannot modify.`}</p>
    <p>{`In case of any ambiguities or problems related to the ticket order, Armada Konferencije DOO will contact the user/buyer of the ticket without delay.`}</p>
    <p>{`Official sales partner for the event will be announced when the ticket sales start.`}</p>
    <p>{`PAYMENT`}</p>
    <p>{`Payment of the ticket through the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` can be made with payment cards.`}</p>
    <p>{`An invoice with purchase confirmation is sent to the user/ticket buyer by e-mail.`}</p>
    <p>{`All prices shown on the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` are expressed in EUR in gross amount, with all associated taxes.
All orders are subject to the applicable prices at the time of payment confirmation.`}</p>
    <p>{`Armada Konferencije DOO reserves the right to change the price from time to time.
The purchase of tickets with special price benefits and/or with special sales incentives is done in accordance with the conditions published on the website.`}</p>
    <p>{`The user/purchaser of the ticket will be responsible for the payment of all taxes associated with his purchase, except those taxes based on the net income of Armada Konferencije DOO, including but not limited to value added tax (VAT).`}</p>
    <p>{`Armada Konferencije DOO is in no way responsible for the provision of visas for the user/buyer of the ticket to enter the country where the event is held,
i.e. by purchasing the ticket, the user/buyer unconditionally undertakes to obtain all necessary permits and visas for entry to the country in his own organization and in a timely manner in relation to the time of the event holds the event,
and bears the costs of the aforementioned in full, without the right to a partial and/or full refund of such costs by Armada Konferencije DOO.`}</p>
    <p>{`During his stay in the country where the event is being held, the user/buyer of the ticket is obliged to fully respect the generally accepted norms of behavior,
all valid regulations of the said country and the rules that apply in the hall where the event is being held and the hotel where the user/buyer is staying at.`}</p>
    <p>{`The user/buyer of the ticket, and therefore, by purchasing the ticket, he accepts the stated obligations and agrees with the fact that he will be solely personally materially and/or misdemeanor and/or criminally responsible in case of any violation thereof.`}</p>
    <h3>{`WITHDRAWAL OF CONTRACT`}</h3>
    <p>{`The user/buyer of the ticket can withdraw from the Agreement within 14 (fourteen) days, counting from the day of payment of the ticket, no later than the start date of the event/conference, by submitting a written notice of withdrawal to e-mail: `}<a parentName="p" {...{
        "href": "mailto:tickets@armada-js.com"
      }}>{`tickets@armada-js.com`}</a></p>
    <p>{`In case of withdrawal from the Agreement in the manner and within the period defined in the previous paragraph, the user/buyer of the ticket will be refunded within 30 (thirty) days from the date of receipt of his notification of withdrawal.`}</p>
    <p>{`In the event that the user/buyer of the ticket fails to submit his written notice of withdrawal from the Agreement in the manner described above within 14 (fourteen) days from the date of payment of the ticket, no later than the start date of the event/conference, his request for withdrawal will be considered untimely, i.e. he will lose the right to withdraw from Contracts and refunds.`}</p>
    <p>{`In case of non-fulfillment of obligations by Armada Konferencije DOO or cancellation of the event for any reason, Armada Konferencije DOO will inform the user/buyer of the ticket as soon as possible about the new circumstances and refund the money.`}</p>
    <p>{`Armada Konferencije DOO will not be responsible for any form of damage that the user/ticket buyer suffers due to event cancellation.`}</p>
    <h3>{`PRIVACY POLICY`}</h3>
    <p>{`In order to provide services, Armada Konferencije DOO will collect and use certain personal data of users.
The Privacy Policy of Armada Konferencije DOO explains in detail how Armada Konferencije DOO processes and protects personal data that it acquires, stores or controls, and describes the technical and organizational measures that are implemented in order to maintain the security, confidentiality and integrity of such data.`}</p>
    <p>{`The Privacy Policy of Armada Konferencije DOO is available on the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` and therefore it is assumed that visitors/users of the said website are familiar with this Privacy Policy and that they agree with it.`}</p>
    <h3>{`USE OF PHOTO MATERIALS AND VIDEO MATERIALS`}</h3>
    <p>{`Armada Konferencije DOO hereby informs the user/buyer of the ticket of the fact that the visitors/participants of the event for which the ticket was purchased will be filmed and photographed during the event and that the material collected in the manner described will be used during and after the event for the purpose of informing the media and the public , as well as for marketing purposes.`}</p>
    <p>{`By purchasing a ticket for the event, each customer gives their irrevocable and unconditional consent to be photographed and recorded during the event, and that Armada Konferencije DOO may publish the material collected in the manner described on its official website, on the website `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` , on its Instagram profile, on its Twitter profile, on its Facebook page, on the You Tube channel, in printed and electronic media and promotional materials, all for the purposes of informing the media and the public, as well as for marketing purposes.`}</p>
    <h3>{`INTELLECTUAL PROPERTY`}</h3>
    <p>{`Except as expressly stated in this Agreement and any other applicable terms of use governing the use of Armada Konferencije DOO services,
Armada Konferencije DOO provides the `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` site and services on an “as available” basis and assumes no liability for any use or reliance on said site, Armada Konferencije DOO services or any information provided through Armada Konferencije DOO services, nor for any interference or delay in the provision of Armada Konferencije DOO services.`}</p>
    <p>{`Armada Konferencije DOO does not make any guarantees or representations, express, statutory or implied, regarding the quality and accuracy of the site `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`,
the services of Armada Konferencije DOO or any information provided through the services of Armada Konferencije DOO and disclaims any implied warranties and representations to the maximum extent permitted by applicable law.`}</p>
    <p>{`Armada Konferencije DOO makes no representations about the accuracy, timeliness, comprehensiveness, completeness, quality, reliability, currency, error-freeness, compatibility, security, loss of data, non-interference or non-infringement of any intellectual property rights or fitness for a particular purpose of the Armada Konferencije DOO services.
Armada Konferencije DOO or any information provided through the services of Armada Konferencije DOO to the maximum extent permitted by applicable law.`}</p>
    <h4>{`No guarantee of safety`}</h4>
    <p>{`Armada Konferencije DOO does not guarantee the adequacy of the services of Armada Konferencije DOO or their compatibility and security with the computer equipment of the visitor/user and does not guarantee that the services of Armada Konferencije DOO, their infrastructure or any electronic mail or communication transmitted through the services of Armada Konferencije DOO be virus-free or protected from hacker attacks.`}</p>
    <h4>{`Exclusion of Warranties`}</h4>
    <p>{`If applicable law does not allow the exclusion of some or all of the above warranties to apply to a visitor/user, the above exclusions will apply to the visitor/user to the fullest extent permitted by applicable law.`}</p>
    <h3>{`LIMITATION OF LIABILITY`}</h3>
    <p>{`To the maximum extent permitted by applicable law, Armada Konferencije DOO shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to damages for, in connection with, or arising out of (a) loss gain, (b) malfunctions in telecommunications, Internet, electronic communications, (c) corruption of data, (d) breach of security, (e) loss or theft of data, (f) viruses or spyware.`}</p>
    <p>{`The page `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` may contain links to other web pages (“Linked Sites”). Armada Konferencije DOO has no control over those pages, nor is it responsible in any way for their content, including without limitation a link to another page or changes and updates to such a page. Armada Konferencije DOO is not responsible for webcasting or other type of transmission received from the linked site.`}</p>
    <h3>{`CHANGES TO CONTRACT AND SERVICES`}</h3>
    <p>{`Armada Konferencije DOO reserves the right to change this Agreement at any time at its sole discretion.
If Armada Konferencije DOO decides to change this Agreement in the future, Armada Konferencije DOO will place an appropriate notice at the top of this page and/or provide advance notice to visitors/users via Armada Konferencije DOO services or by other means (e.g. via electronic notification by mail).
Any non-material change (such as clarifications) to this Agreement will take effect on the day the change is posted, and all material changes will take effect 3 (three) days after they are posted on the site.
The date this Agreement was last updated is listed at the top of this document.`}</p>
    <p>{`You acknowledge and agree that your continued use of Armada Konferencije DOO services after the date of changes to this Agreement indicates that you agree to such changes.`}</p>
    <h3>{`GOVERNING LAW`}</h3>
    <p>{`You agree that this Agreement will be regulated and interpreted in accordance with the laws of the Republic of Serbia, and all disputes related to these terms and conditions will be under the exclusive jurisdiction of the courts of the Republic of Serbia.`}</p>
    <h3>{`FINAL REGULATIONS`}</h3>
    <h4>{`Relationship of parties`}</h4>
    <p>{`The parties to this Agreement shall act solely as independent contractors.`}</p>
    <p>{`This Agreement shall not be construed as creating an agency, partnership, joint venture, fiduciary obligation or any other form of legal association between You and Armada Konferencije DOO, and You shall not represent to the contrary, whether expressly, implicitly, apparently or otherwise.`}</p>
    <h4>{`Completeness of the Agreement`}</h4>
    <p>{`Unless expressly stated elsewhere on this site, this Agreement, including the Privacy Policy and any other documents stated to apply to you as a user of the Services, constitutes the entire Agreement between the Client/User and Armada Konferencije DOO regarding the use the Armada Konferencije DOO service replaces all previous agreements, communications and contracts, oral or written, related to the subject of this document.`}</p>
    <h4>{`Separability of provisions`}</h4>
    <p>{`If any provision of this Agreement is held to be illegal, invalid, void or unenforceable, in whole or in part, by any court of competent jurisdiction, the remainder of the terms and conditions set forth in this Agreement shall remain in full force and effect and shall not be modified in any way, violated or invalidated.
Such illegal, invalid, void or unenforceable term or condition or part thereof shall be deemed modified to the extent necessary to make it enforceable; otherwise, it will be severed from this Agreement, which will continue in full force and effect and be binding on You.`}</p>
    <h4>{`Titles`}</h4>
    <p>{`The section headings contained in this Agreement are for reference purposes only and shall not affect the meaning or interpretation of this Agreement.`}</p>
    <h4>{`Official language`}</h4>
    <p>{`The original language of this Agreement is Serbian. Armada Konferencije DOO may make translations available for convenience.
In the event of any discrepancy between the original Serbian version and any translation, the Serbian version shall prevail.`}</p>
    <hr></hr>
    <h1>{`Uslovi korišćenja i prodaje`}</h1>
    <p>{`Armada Konferencije doo`}</p>
    <p>{`Poslednje ažuriranje: 15.05.2023.`}</p>
    <p>{`Ovi Uslovi korišćenja i prodaje primenjuju se na pružanje usluga koje organizuje Armada Konferencije DOO sa sedištem u Ulici Dositejeva 7/26 u Novom Sadu putem internet stranice `}<a parentName="p" {...{
        "href": "https://armada-js.com."
      }}>{`https://armada-js.com.`}</a>{`
Ovi Uslovi korišćenja i prodaje definišu opšte uslove, pojedinačna prava i obaveze i način kupovine.`}</p>
    <p>{`Upotreba internet stranice '`}<a parentName="p" {...{
        "href": "https://armada-js.com'"
      }}>{`https://armada-js.com'`}</a>{` reguliše se ovim Uslovima korišćenja i prodaje, koje posetilac/korisnik mora pažljivo pročitati, jer se korišćenje internet stranice '`}<a parentName="p" {...{
        "href": "https://armada-js.com'"
      }}>{`https://armada-js.com'`}</a>{` smatra bezuslovnim prihvatanjem ovih Uslova korišćenja i prodaje.
Ako se posetilac/korisnik ne slaže sa uslovima korišćenja ovog dokumenta, ne sme koristiti usluge i sadržaj internet stranice '`}<a parentName="p" {...{
        "href": "https://armada-js.com'"
      }}>{`https://armada-js.com'`}</a>{` .
Ovi Uslovi korišćenja i prodaje su pravno obavezujući Ugovor između Vas i Armada Konferencije DOO.
Registracijom/korišćenjem i kupovinom potvrđujete da ste pročitali, razumeli i saglasni da stupite u ovaj Ugovor.
Ako niste saglasni sa Ugovorom, bilo delimično ili u potpunosti nemojte pristupiti, registrovati se i poručivati ili na bilo koji drugi način koristiti Armada Konferencije DOO usluge.`}</p>
    <p>{`Ovaj Ugovor se smatra zaključenim u trenutku potvrde porudžbine od strane Armada Konferencije DOO.`}</p>
    <h3>{`OSNOVNE ODREDBE`}</h3>
    <p>{`Armada Konferencije DOO, sa sedištem u Ulici Dositejeva 7/26 u Novom Sadu, PIB: 113186670, MB: 21819379, kontakt e-mail: `}<a parentName="p" {...{
        "href": "mailto:contact@armada-js.com"
      }}>{`contact@armada-js.com`}</a>{`, je privredno društvo koje u okviru svoje registrovane delatnosti vrši organizaciju seminara, konferencija i drugih događaja.`}</p>
    <p>{`Armada Konferencije DOO vrši prodaju karata za navedene događaje putem internet stranice `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`, što u smislu zakona predstavlja trgovinu na daljinu.`}</p>
    <h3>{`NAČIN KUPOVINE`}</h3>
    <p>{`Kupovina karata putem sajta `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` može se izvršiti od strane registrovanih i neregistrovanih korisnika navedenog sajta, i to na sledeći način:`}</p>
    <p>{`korak: klik na opciju “Tickets” koja kupca automatski preusmerava na sajt/portal prodajnog partnera, a preko kojeg se obavlja kupovina karte, čime korisnik prihvata`}</p>
    <p>{`Opšte uslove i Politiku privatnosti portala/sajta prodajnog partnera;`}</p>
    <p>{`korak: na sajtu/portalu partnera za prodaju vrši se unos traženih podataka: ime`}<em parentName="p">{`, prezime`}</em>{`, e-mail adresa`}<em parentName="p">{`, kompanija`}</em>{`, pozicija u kompaniji`}<em parentName="p">{`, prefix/titula, CC/alternativna e-mail adresa, kontakt mobilni telefon, uz napomenu da su podaci koji su označeni sa `}</em>{` obavezni za unos i da će kao takvi biti prosleđeni Armada Konferencije DOO.`}</p>
    <p>{`korak: vršenje plaćanja karte.`}</p>
    <p>{`Nakon prijema porudžbine, Armada Konferencije DOO ili njegov prodajni partner, izvršiće potvrdu porudžbine dostavljanjem obaveštenja o potvrdi porudžbine na e-mail korisnika/kupca karte.
Nakon plaćanja karte, korisniku/kupcu se karta šalje sa e-mail adrese `}<a parentName="p" {...{
        "href": "mailto:tickets@armada-js.com"
      }}>{`tickets@armada-js.com`}</a></p>
    <p>{`Korisnik/kupac karte ima mogućnost da najkasnije do 15 (petnaest) dana pre dana održavanja događaja izvrši modifikaciju određenih podataka dostavljenih prilikom kupovine karte (podaci dostavljeni u koraku broj 2. iz prvog stava).
Korisnik/kupac karte može modifikovati podatke koji se odnose na njegovu e-mail adresu, kompaniju, poziciju u kompaniji, prefix/titula, CC/alternativnu e-mail adresa, rod i kontakt mobilni telefon, dok podatke o svom imenu i prezimenu ni u kom slučaju ne može modifikovati.`}</p>
    <p>{`U slučaju bilo kakvih nejasnoća ili problema vezanih za porudžbinu karte, Armada Konferencije DOO će kontaktirati korisnika/kupca karte bez odlaganja.`}</p>
    <p>{`Zvanični prodajni partner za ovaj događaj je će biti objavljen kad počne prodaja ulaznica.`}</p>
    <h3>{`PLAĆANJE`}</h3>
    <p>{`Plaćanje karte putem sajta `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` je moguće izvršiti platnim karticama ili izdavanjem fakture/e-Fakture ukoliko je kupac pravno lice.`}</p>
    <p>{`Račun sa potvrdom kupovine dostavlja se korisniku/kupcu karte na e-mail.`}</p>
    <p>{`Sve cene koje su iskazane na sajtu `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` su izražene u EUR u bruto iznosu, sa svim pripadajućim porezima.`}</p>
    <p>{`Za sve porudžbine primenjuju se važeće cene u trenutku potvrde plaćanja.`}</p>
    <p>{`Armada Konferencije DOO zadržava pravo da s vremena na vreme izvrši promenu cene.
Kupovina karata uz posebne cenovne pogodnosti i/ili uz posebne prodajne podsticaje obavlja se u skladu sa uslovima koji su objavljeni na sajtu.`}</p>
    <p>{`Korisnik/kupac karte će biti odgovoran za plaćanje svih poreza povezanih sa njegovom kupovinom, osim onih poreza na osnovu neto prihoda Armada Konferencije DOO, uključujući, ali ne ograničavajući se na porez na dodatu vrednost (PDV).`}</p>
    <p>{`Armada Konferencije DOO ni na koji način nije odgovoran za obezbeđivanje vize korisniku/kupcu karte za ulazak u državu u kojoj se održava događaj, odnosno korisnik/kupac karte se kupovinom iste bezuslovno obavezuje da u sopstvenoj organizaciji i blagovremeno u odnosu na vreme održavanja događaja pribavi sve potrebne dozvole i vize za ulazak u državu u kojoj se održava događaj, te da u celosti snosi troškove navedenog, bez prava na delimičnu i/ili potpunu refundaciju takvih troškova od strane Armada Konferencije DOO.`}</p>
    <p>{`Korisnik/kupac karte je tokom svog boravka u državi u kojoj se održava događaj u obavezi da u potpunosti poštuje opšteprihvaćene norme ponašanja, sve važeće propise navedene države i pravila koja važe u sali u kojoj se održava događaj i hotelu u kojem je korisnik/kupac karte smešten, te stoga kupovinom karte prihvata navedene obaveze i saglasan je sa činjenicom da će biti isključivo lično materijalno i/ili prekršajno i/ili krivično odgovoran u slučaju bilo kakvog kršenja istih.`}</p>
    <h3>{`ODUSTANAK OD UGOVORA`}</h3>
    <p>{`Korisnik/kupac karte može odustati od Ugovora u roku od 14 (četrnaest) dana, računajući od dana izvršenog plaćanja karte, ne kasnije od jednog dana pre početka konferencije/događaja, dostavljanjem pisanog obaveštenja o odustanku na e-mail: `}<a parentName="p" {...{
        "href": "mailto:tickets@armada-js.com"
      }}>{`tickets@armada-js.com`}</a></p>
    <p>{`U slučaju odustanka od Ugovora na način i u roku definisanom u prethodnom stavu, korisniku/kupcu karte će biti izvršen povraćaj novca u roku od 30 (trideset) dana od dana prijema njegovog obaveštenja o odustanku.`}</p>
    <p>{`U slučaju da korisnik/kupac karte propusti da u roku od 14 (četrnaest) dana od dana izvršenog plaćanja karte dostavi svoje pisano obaveštenje o odustanku od Ugovora na napred opisani način, njegov zahtev za odustanak će se smatrati neblagovremenim, odnosno izgubiće pravo na odustanak od Ugovora i povraćaj novca.`}</p>
    <p>{`U slučaju neizvršavanja obaveza od strane Armada Konferencije DOO ili otkazivanja događaja iz bilo kog razloga, Armada Konferencije DOO će u što kraćem roku obavestiti korisnika/kupca karte o novonastalim okolnostima i izvršiti povraćaj novca.`}</p>
    <p>{`Armada Konferencije DOO neće biti odgovoran za bilo koji oblik štete koju korisnik/kupac karte pretrpi usled otkazivanja događaja.`}</p>
    <h3>{`POLITIKA PRIVATNOSTI`}</h3>
    <p>{`U cilju pružanja usluga, Armada Konferencije DOO će prikupljati i koristiti određene lične podatke korisnika. Politika privatnosti Armada Konferencije DOO detaljno objašnjava kako Armada Konferencije DOO obrađuje i štiti lične podatke koje pribavlja, čuva ili kontroliše, te opisuje tehničke i organizacione mere koje se primenjuju u cilju održavanja bezbednosti, poverljivosti i integriteta takvih podataka.`}</p>
    <p>{`Politika privatnosti Armada Konferencije DOO je dostupna na sajtu `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` i stoga se podrazumeva da su posetioci/korisnici navedenog sajta upoznati sa ovom Politikom privatnosti i da su saglasni sa istom.`}</p>
    <h3>{`KORIŠĆENJE FOTO MATERIJALA I VIDEO MATERIJALA`}</h3>
    <p>{`Armada Konferencije DOO ovim putem upoznaje korisnika/kupca karte sa činjenicom da će posetioci/učesnici događaja za koji je kupljena karta biti snimani i fotografisani tokom događaja i da će se materijal prikupljen na opisani način koristiti tokom i nakon događaja u svrhu informisanja medija i javnosti, kao i u marketinške svrhe.`}</p>
    <p>{`Kupovinom karte za događaj svaki kupac iste daje svoj neopozivi i bezuslovni pristanak da može tokom događaja da bude fotografisan i sniman, te da Armada Konferencije DOO može materijal prikupljen na opisani način objaviti na svom zvaničnom sajtu, na sajtu `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`, na svom Instagram profilu, na svom Twitter profilu, na svojoj Facebook strani, na You Tube kanalu, u štampanim i elektronskim medijima i promo-materijalima, sve za potrebe informisanja medija i javnosti, kao i u marketinške svrhe.`}</p>
    <h3>{`INTELEKTUALNA SVOJINA`}</h3>
    <p>{`Sav sadržaj koji se nalazi na stranici `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`, uključujući, ali bez ograničenja na njih, tekstove, grafiku, slike, fotografije, video i zvučne zapise, predstavlja intelektualnu svojinu Armada Konferencije DOO ili trećih lica od kojih je Armada Konferencije DOO dobio saglasnost za njegovo korišćenje i kao takav je zaštićen prema važećem nacionalnom i međunarodnom pravu.
Zabranjena je svaka upotreba sadržaja sajta `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` u komercijalne ili u druge svrhe bez izričite pisane saglasnosti Armada Konferencije DOO.`}</p>
    <p>{`Sadržaj stranice `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` dostupan je posetiocima/korisnicima isključivo za ličnu upotrebu i podložan je promeni bez prethodnog obaveštenja, prema diskreciji Armada Konferencije DOO.
U slučaju da posetilac/korisnik želi da izvrši bilo koju drugu upotrebu sajta osim lične, potrebno je da za navedeno prethodno pribavi pisano odobrenje izdato od strane Armada Konferencije DOO.`}</p>
    <h3>{`ODRICANJE ODGOVORNOSTI`}</h3>
    <p>{`Osim ako je izričito navedeno u ovom Ugovoru i svim drugim primenljivim uslovima korišćenja koji regulišu korišćenje usluga Armada Konferencije DOO, Armada Konferencije DOO nudi sajt `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` i usluge na osnovi “kao što je dostupno” i ne prihvata odgovornost za bilo kakvu upotrebu ili oslanjanje na navedeni sajt, usluge Armada Konferencije DOO ili bilo koje informacije pružene putem usluga Armada Konferencije DOO, niti za bilo koje smetnje ili kašnjenja u pružanju usluga Armada Konferencije DOO.`}</p>
    <p>{`Armada Konferencije DOO ne daje nikakve garancije ili izjave, izričite, zakonske ili implicirane, u pogledu kvaliteta i tačnosti sajta `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{`, usluga Armada Konferencije DOO ili bilo koje informacije pružene putem usluga Armada Konferencije DOO i odriče se bilo kakvih podrazumevanih garancija i izjava do makasimalnog stepena dozvoljenog važećim zakonom.`}</p>
    <p>{`Armada Konferencije DOO ne daje nikakve izjave o tačnosti, blagovremenosti, sveobuhvatnosti, potpunosti, kvalitetu, pouzdanosti, valuti, svojstvu da nema grešaka, kompatibilnosti, bezbednosti, gubitku podataka, nemešanju ili nekršenju bilo kojih prava intelektualne svojine ili prikladnosti za određenu svrhu usluga Armada Konferencije DOO ili bilo koje informacije pružene putem usluga Armada Konferencije DOO do maksimalnog stepena dozvoljenog važećim zakonom.`}</p>
    <p>{`Bez garancije sigurnosti. Armada Konferencije DOO ne garantuje adekvatnost usluga Armada Konferencije DOO ili njihovu kompatibilnost i sigurnost sa računarskom opremom posetioca/korisnika i ne garantuje da će usluge Armada Konferencije DOO, njihova infrastruktura ili bilo koja elektronska pošta ili komunikacija preneta putem usluga Armada Konferencije DOO biti bez virusa ili zaštićeni od hakerskih napada.`}</p>
    <h4>{`Isključivanje garancija`}</h4>
    <p>{`Ako važeći zakon ne dozvoljava da se isključivanje nekih ili svih gore navedenih garancija odnosi na posetioca/korisnika, gore navedena isključenja će se primenjivati na posetioca/korisnika u najvećoj meri dozvoljenoj važećim zakonom.`}</p>
    <h3>{`OGRANIČENJE ODGOVORNOSTI`}</h3>
    <p>{`U maksimalnoj meri dozvoljenoj važećim zakonom, Armada Konferencije DOO neće biti odgovoran za bilo kakvu direktnu, indirektnu, slučajnu, posebnu, posledičnu ili primernu štetu, uključujući, ali ne ograničavajući se na štetu za, u vezi sa ili proisteklu iz (a) gubitka dobiti, (b) kvarova u telekomunikacijama, internetu, elektronskim komunikacijama, (c) oštećenja podataka, (d) ​​narušavanja bezbednosti, (e) gubitka ili krađe podataka, (f) virusa ili špijunskog softvera.`}</p>
    <p>{`Stranica `}<a parentName="p" {...{
        "href": "https://armada-js.com"
      }}>{`https://armada-js.com`}</a>{` može sadržati veze prema drugim web stranicama (“Linked Sites”).
Armada Konferencije DOO nema kontrolu nad tim stranicama, niti je na bilo koji način odgovoran za njihov sadržaj, uključujući bez ograničenja vezu prema nekoj drugoj stranici ili izmene i ažuriranje takve stranice.
Armada Konferencije DOO nije odgovoran za webcasting ili drugu vrstu prenosa koju primi s povezane stranice.`}</p>
    <h3>{`IZMENE UGOVORA I USLUGA`}</h3>
    <p>{`Armada Konferencije DOO zadržava pravo da promeni ovaj Ugovor u bilo kom trenutku po sopstvenom nahođenju.
Ako Armada Konferencije DOO odluči da promeni ovaj Ugovor u budućnosti, Armada Konferencije DOO će postaviti odgovarajuće obaveštenje na vrhu ove stranice i/ili unapred dati obaveštenje posetiocima/korisnicima putem usluga Armada Konferencije DOO ili na drugi način (npr. putem obaveštenja elektronskom poštom).
Svaka nematerijalna promena (kao što su pojašnjenja) ovog Ugovora će stupiti na snagu na dan kada je promena objavljena, a sve materijalne promene će stupiti na snagu 3 (tri) dana od njihovog objavljivanja na sajtu.
Datum poslednjeg ažuriranja ovog Ugovora je naveden na vrhu ovog dokumenta.`}</p>
    <p>{`Potvrđujete i saglasni ste da Vaše dalje korišćenje usluga Armada Konferencije DOO nakon datuma promena ovog Ugovora ukazuje na to da ste saglasni sa takvim promenama.`}</p>
    <h3>{`NADLEŽNO PRAVO`}</h3>
    <p>{`Saglasni ste da će ovaj Ugovor biti regulisan i tumačen u skladu sa zakonima Republike Srbije, a svi sporovi u vezi sa ovim uslovima i odredbama biće u isključivoj nadležnosti sudova u Republici Srbiji.`}</p>
    <h3>{`ZAVRŠNE ODREDBE`}</h3>
    <h4>{`Odnos strana`}</h4>
    <p>{`Strane u ovom Ugovoru će delovati isključivo kao nezavisni ugovarači.`}</p>
    <p>{`Ovaj Ugovor se neće tumačiti kao stvaranje zastupanja, partnerstva, zajedničkog ulaganja, fiducijarne obaveze ili bilo kog drugog oblika pravnog povezivanja između Vas i Armada Konferencije DOO, a Vi nećete zastupati suprotno, bilo izričito, implicitno, naizgled ili na neki drugi način.`}</p>
    <h4>{`Celovitost Ugovora`}</h4>
    <p>{`Osim ako je izričito navedeno na drugom mestu na ovom sajtu, ovaj Ugovor, uključujući Politiku privatnosti i sve druge dokumente za koje je navedeno da se primenjuju na Vas kao korisnika usluga, predstavlja ceo Ugovor između Klijenta/Korisnika i Armada Konferencije DOO u pogledu korišćenja usluga Armada Konferencije DOO zamenjuje sve prethodne sporazume, komunikacije i ugovore, usmene ili pismene, u vezi sa predmetom ovog dokumenta.`}</p>
    <h4>{`Odvojivost odredbi`}</h4>
    <p>{`Ako bilo koja odredba ovog Ugovora bude smatrana nezakonitom, nevažećom, ništavom ili neizvršivom, u celini ili delimično, od strane bilo kog nadležnog suda, ostatak uslova i odredbi navedenih u ovom Ugovoru ostaće na punoj snazi i dejstvu i ni na koji način neće biti izmenjeni, narušeni ili poništeni.
Takva nezakonita, nevažeća, ništava ili nesprovodljiva odredba ili uslov ili njen deo smatraće se izmenjenim u meri koja je potrebna da bi se učinila primenljivom; u suprotnom, biće odvojena od ovog Ugovora, koji će nastaviti sa punom snagom i dejstvom i biti obavezujući za Vas.`}</p>
    <h4>{`Naslovi`}</h4>
    <p>{`Naslovi odeljaka sadržani u ovom Ugovoru služe samo u referentne svrhe i neće uticati na značenje ili tumačenje ovog Ugovora.`}</p>
    <h4>{`Merodavan jezik`}</h4>
    <p>{`Originalni jezik ovog Ugovora je srpski. Armada Konferencije DOO može učiniti dostupnim prevode radi pogodnosti.
U slučaju neslaganja između originalne srpske verzije i bilo kog prevoda, srpska verzija će imati prednost.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      